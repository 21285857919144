const backToTopButton = document.querySelector('[data-js-back-to-top-button]');

if (backToTopButton) {
    backToTopButton.addEventListener('click', () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });
}

export {};
